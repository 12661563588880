const FaBars = (props: { className?: string }) => {
  return (
    <svg
      aria-hidden="true" 
      focusable="false" 
      data-prefix="fas" 
      data-icon="bars" 
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      className={`${props.className ?? ""}`} 
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 7C2.25 6.58579 2.58579 6.25 3 6.25H21C21.4142 6.25 21.75 6.58579 21.75 7C21.75 7.41421 21.4142 7.75 21 7.75H3C2.58579 7.75 2.25 7.41421 2.25 7Z"
        fill="auto"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 12C2.25 11.5858 2.58579 11.25 3 11.25H21C21.4142 11.25 21.75 11.5858 21.75 12C21.75 12.4142 21.4142 12.75 21 12.75H3C2.58579 12.75 2.25 12.4142 2.25 12Z"
        fill="auto"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 17C2.25 16.5858 2.58579 16.25 3 16.25H21C21.4142 16.25 21.75 16.5858 21.75 17C21.75 17.4142 21.4142 17.75 21 17.75H3C2.58579 17.75 2.25 17.4142 2.25 17Z"
        fill="auto"
      />
    </svg>
  );
}

export default FaBars;