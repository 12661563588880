import { useDispatch } from "react-redux";
import FaBars from "../SVG/Fontawesome/FaBars";
import { updateOffCanvasView } from "@/store/slices/offCanvas";


const HamburgerButton = (props: {
  className?: string;
  id?: string;
}) => {
  const dispatch = useDispatch();

  return <button
    onClick={() => dispatch(updateOffCanvasView({ name: "HAMBURGER" }))}
    id={props.id ?? "mobile-hamburger-btn"}
    name="hamburger-btn"
    className={`hamburger-btn block sm:hidden ${props.className ?? ""} fill-body  transition duration-150 ease-in-out`}
    type="button"
  >
    <FaBars className="h-6 w-6 sm:w-8 sm:h-8" />
  </button>
}


export default HamburgerButton;