import { useEffect, useState } from "react";
import { isAndroid, isIOS } from "react-device-detect";

const AppInstallBtn = (props: {
  pageProps?: any;
}) => {
  const { question_id: questionId } = props.pageProps?.videoData || {};

  const appInstallProps: any = {};
  const isEven = questionId % 2 === 0;
  if (isEven) {
    appInstallProps["text"] = "Download Allen App";
    appInstallProps["playStoreLink"] = "https://allen.onelink.me/90jx/wy779qen";
    appInstallProps["appStoreLink"] = "https://apps.apple.com/in/app/allen-neet-jee-class-6-12/id6449251524?utm_source=Doubtnut&utm_medium=doubtnut_web&utm_campaign=doubtnut_download_allen"
  }

  return <AppInstallBtnContent {...appInstallProps}/>
}

const AppInstallBtnContent = (props: {
  playStoreLink?: string;
  appStoreLink?: string;
  text?: string;
}) => {
  const [storeLink, setStoreLink] = useState<string>("");
  const text = props.text ?? "Ask Doubt on Allen";
  
  useEffect(() => {
    if(isIOS){
      setStoreLink(props.appStoreLink ?? "https://apps.apple.com/in/app/allen-neet-jee-class-6-12/id6449251524?utm_source=Doubtnut&utm_medium=doubtnut_web&utm_campaign=doubtnut_ask_doubt");
      return;
    }

    if(isAndroid){
      setStoreLink(props.playStoreLink ?? "https://allen.onelink.me/90jx/1q4zkwnl");
      return;
    }
  }, [])


  return (
    <button id="ask-doubt-app" className="ml-auto sm:ml-0 lg:hidden bg-accent font-semibold uppercase rounded-full px-2 text-xs text-white fill-primary">
      {/* eslint-disable-next-line react/jsx-no-target-blank */}
      <a
        href={storeLink}
        target="_blank"
        className="w-max py-2 px-1 normal-case flex items-center gap-2"
      >
        <span>{text}</span>
      </a>
    </button>
  );
}

export default AppInstallBtn