import { memo } from "react";
import RightIcons from "./RightIcons";
import dynamic from "next/dynamic";
import AllenDNLogo from "../SVG/AllenDNLogo";
import HamburgerButton from "./HamburgerButton";
import AppInstallBtn from "../Buttons/AppInstallBtn";

const Navbar = dynamic(() => import("./Navbar"), {
  ssr: false
});

const Header = (props: {
  pageProps?: any;
}) => {

  return <header className="w-full bg-secondary flex flex-col px-5 sm:px-28 py-1 z-30 fill-primary">
    <div className="flex justify-between h-14 lg:h-16 ">
      <div className="flex items-center gap-4 lg:gap-8 grow">
        <HamburgerButton />
       
        <a href="/" className="flex items-center" id="hd-logo">
          <AllenDNLogo className="h-8 sm:h-10" />
        </a>
        <AppInstallBtn pageProps={props.pageProps} />
        <Navbar />
      </div>
      <RightIcons />
    </div>
  </header>
};


export default memo(Header);