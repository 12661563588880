import BreadCrumb from "./Breadcrumb";
import { memo } from "react";
import TopHeader from "./Header";
import { useRouter } from "next/router";

const Header = (props: { breadcrumbs?: { items?: { url: string; text: string; }[]; className?: string }, pageProps?: any }) => {
  const breadcrumbs = props.breadcrumbs?.items?.slice(0, props.breadcrumbs?.items?.length - 1) || [];
  const currentText = props.breadcrumbs?.items?.[props.breadcrumbs?.items?.length - 1]?.text;

  const router = useRouter();
  const excludeBreadCrumbPages = ["/VideoPage"];
  const showBreadCrumb = !excludeBreadCrumbPages.includes(router.pathname)
  

  return (
    <>
      <TopHeader pageProps={props.pageProps}/>      
     
      <hr className="h-[0.5px] border-none bg-primary-light" />
      {
        showBreadCrumb && props.breadcrumbs && currentText && <BreadCrumb breadcrumbs={breadcrumbs} currentText={currentText} className={props.breadcrumbs?.className} />
      }
    </>
  );
};

export default memo(Header);
