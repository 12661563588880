import BottomNav from "@/components/BottomNavbar";
import dynamic from "next/dynamic";
import { memo, useMemo } from "react";
import { DEEPLINK_PREFIX, STATIC_CDN } from "@/config/constants";
import Header from "@/components/Header";
// import ClosableBanner from "@/components/Banner/ClosableBanner";
import { jakarta } from "@/helpers/font";

const Footer = dynamic(() => import("@/components/Footer"));
const VideoModal = dynamic(() => import("@/components/Dialog/Video"));

const CommonLayout = (props: { children: React.ReactNode }) => {
  const pageProps = (props.children as any)?.[0]?.props?.children?.props;
  const pathName = pageProps?.pathName;
  const breadcrumbs = pageProps?.breadcrumbs;
  const { class: studentClass, target_group: targetGroup, target_group_type: targetGroupType } = pageProps?.videoData || {};

  const imageBannerData = useMemo( () =>
    ({
      imgUrl: (studentClass && targetGroup && targetGroupType) ?  `${STATIC_CDN}/web_banners/top-sticky-promotions-banner/${studentClass}-${targetGroup.toUpperCase().replace(/\s+/g, "-")}-${targetGroupType.toUpperCase().replace(/\s+/g, "-")}.webp` : `${STATIC_CDN}/web_banners/top-sticky-promotions-banner/default.webp`,
      alt: "Doubtnut Promotions Sticky",
      href: `${DEEPLINK_PREFIX}/_s6cQrh9d5NLz`,
      width: 360,
      height: 90,
      closable: false,
    }), [studentClass, targetGroup, targetGroupType]);


  return (
    <div id="base-layout" className={`${jakarta.variable} font-sans`}>
      {/* <ClosableBanner data={imageBannerData} />*/}
      <Header breadcrumbs={breadcrumbs} pageProps={pageProps} />
      {props.children}
      <Footer />
      <VideoModal page={pathName} />
      <BottomNav pageProps={pageProps}/>
    </div>
  );
};

export default memo(CommonLayout);